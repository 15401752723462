import { Container } from "@mui/material";
import { useSelector } from "react-redux";

import BottomDrawer from "@/components/BottomDrawer";
import { useData } from "@/contexts/DataContext";

import DeliveryCalendar from "./Period/DeliveryCalendar/DeliveryCalendar";
import generateOrderDates from "./Period/utils/generateOrderDates";

const CalendarDrawer = ({ open, setOpen }) => {
  const { dayToNumberMap, periodToDaysMap } = useData();
  const { kitchen, startDate, period } = useSelector(
    (state) => state.orderSlice.order,
  );

  const isDataAvailable = Boolean(startDate && period);

  if (!isDataAvailable) {
    return null;
  }

  const { deliverableDates, endDate } = generateOrderDates({
    startDate,
    dayToNumberMap,
    periodToDaysMap,
    period,
    deliverableDays: kitchen.configuration.deliveryDays,
  });

  return (
    <BottomDrawer isOpen={open} setIsOpen={setOpen} title={"Delivery Calendar"}>
      <Container>
        <DeliveryCalendar
          startDate={startDate}
          endDate={endDate}
          deliverableDates={deliverableDates}
        />
      </Container>
    </BottomDrawer>
  );
};

export default CalendarDrawer;
