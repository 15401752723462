import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import kitchenImg from "@/assets/images/kitchen.jpg";
import { orderActions } from "@/store/order";

import KitchenRatings from "./KitchenRatings";

const KitchenCard = ({ kitchen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cuisine, mealType, mealTime, period } = useSelector(
    (state) => state.kitchensSlice,
  );

  const kitchenClickHandler = (kitchen) => {
    const orderData = {
      kitchen,
    };
    if (cuisine) orderData.cuisine = cuisine;
    if (mealType) orderData.mealType = mealType;
    if (mealTime) orderData.mealTime = mealTime;
    if (period) orderData.period = period;
    dispatch(orderActions.resetOrder(orderData));
    navigate(`/kitchens/${kitchen._id}`);
  };

  return (
    <Card
      style={{ cursor: "pointer", margin: "15px 0px" }}
      onClick={() => kitchenClickHandler(kitchen)}
    >
      <CardMedia
        component="img"
        height="90"
        image={kitchen.coverImage.s3Location || kitchenImg}
        alt={kitchen.name}
      />
      <CardContent>
        <Typography
          variant="h5"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {kitchen.name}
        </Typography>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <KitchenRatings
            ratingValue={kitchen.rating.average}
            numRatings={kitchen.rating.count}
          />
          <Typography variant="subtitle1" color="text.secondary">
            ₹{kitchen.pricing.basePrice}
          </Typography>
        </div>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {kitchen.tagline}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default KitchenCard;
