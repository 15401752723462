import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { orderActions } from "@/store/order";
import { verticalComponentMargin } from "@/utils/styleConstants";

import Label from "../Label";

const Quantity = () => {
  const dispatch = useDispatch();
  const { quantity } = useSelector((state) => state.orderSlice.order);

  const setQuantity = (quantity) => {
    dispatch(orderActions.updateOrder({ quantity }));
  };

  const increment = () => {
    if (quantity < 10) {
      setQuantity(quantity + 1);
    }
  };

  const decrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  return (
    <Card variant="outlined" style={{ margin: verticalComponentMargin }}>
      <CardContent
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Label label={"Select Quantity"} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <QuantityChangeButton
            icon={<RemoveIcon />}
            clickHandler={decrement}
          />
          <QuantityDisplay quantity={quantity} />
          <QuantityChangeButton icon={<AddIcon />} clickHandler={increment} />
        </div>
      </CardContent>
    </Card>
  );
};

export default Quantity;

const QuantityChangeButton = ({ icon, clickHandler }) => (
  <Button
    size="small"
    variant="outlined"
    style={{ cursor: "pointer", minWidth: "32px" }}
    onClick={clickHandler}
  >
    {icon}
  </Button>
);

const QuantityDisplay = ({ quantity }) => (
  <Typography
    style={{ width: "20px", textAlign: "center", margin: "0px 10px" }}
  >
    {quantity}
  </Typography>
);
