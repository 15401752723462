import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

import api from "@/api";
import useDebounce from "@/hooks/useDebounce";
import handleError from "@/utils/handleError";

const LocationSearch = ({ searchValue, setSearchValue, setSuggestions }) => {
  const [loading, setLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue.trim(), 300);

  const handleClear = () => {
    setSearchValue("");
  };

  useEffect(() => {
    const getPlacesAutocomplete = async () => {
      setLoading(true);
      try {
        const {
          data: { suggestions },
        } = await api.maps.getPlacesAutocomplete({
          input: debouncedSearchValue,
        });
        if (suggestions) setSuggestions(suggestions);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    if (debouncedSearchValue) {
      getPlacesAutocomplete();
    } else {
      setSuggestions([]);
      setLoading(false);
    }
  }, [debouncedSearchValue, setSuggestions]);

  return (
    <TextField
      style={{ marginBottom: "20px" }}
      fullWidth
      size="small"
      placeholder="Search Location"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {loading ? (
              <IconButton disabled>
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              searchValue && (
                <IconButton onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              )
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LocationSearch;
