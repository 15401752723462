import { useCallback, useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import PriceBreakdownDrawer from "./PriceBreakdownDrawer";
import calculatePrice from "./utils/calculatePrice";

const Price = () => {
  const dispatch = useDispatch();
  const { periodToDaysMap } = useData();
  const {
    kitchen: { pricing },
    cuisine,
    mealType,
    mealTime,
    period,
    quantity,
    price,
  } = useSelector((state) => state.orderSlice.order);
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const setPrice = useCallback(
    (price) => {
      dispatch(orderActions.updateOrder({ price }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!cuisine || !mealType || !mealTime || !period || !quantity) {
      return;
    }

    const price = calculatePrice({
      cuisine,
      mealType,
      mealTime,
      period,
      periodInDays: periodToDaysMap[period],
      pricing,
      quantity,
    });

    setPrice(price);
  }, [
    cuisine,
    mealType,
    mealTime,
    period,
    quantity,
    pricing,
    setPrice,
    periodToDaysMap,
  ]);

  if (!price) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        color="success"
        onClick={openDrawer}
        endIcon={<KeyboardArrowUpIcon />}
      >
        Price ₹{price}
      </Button>
      <PriceBreakdownDrawer isOpen={open} setIsOpen={setOpen} />
    </>
  );
};

export default Price;
