import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "@/contexts/AuthContext";
import { orderActions } from "@/store/order";

const BottomButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authCtx = useAuth();
  const { cuisine, mealType, mealTime, period } = useSelector(
    (state) => state.orderSlice.order,
  );
  const { location: gsLocation } = useSelector((state) => state.kitchensSlice);

  const proceedHandler = () => {
    if (!cuisine) {
      return toast.error("Please select a cuisine");
    }
    if (!mealType) {
      return toast.error("Please select a meal type");
    }
    if (!mealTime) {
      return toast.error("Please select a meal time");
    }
    if (!period) {
      return toast.error("Please select a period");
    }

    if (gsLocation.type === "address") {
      dispatch(
        orderActions.updateOrder({
          address: gsLocation.address,
        }),
      );
      return navigate("/create-order/summary-and-payment");
    } else {
      navigate("/create-order/address");
    }
  };

  return (
    <>
      {authCtx.isLoggedIn ? (
        <Button
          size="small"
          variant="contained"
          color={"success"}
          onClick={proceedHandler}
          endIcon={<ArrowForward />}
        >
          {gsLocation.type === "address" ? "Proceed" : "Address"}
        </Button>
      ) : (
        <Button
          size="small"
          variant="contained"
          color="success"
          onClick={() => navigate("/auth")}
          endIcon={<ArrowForward />}
        >
          Sign in
        </Button>
      )}
    </>
  );
};

export default BottomButton;
