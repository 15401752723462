import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import api from "@/api";
import handleError from "@/utils/handleError";

import SelectableAddressCard from "./SelectableAddressCard";

const ExistingAddresses = ({ selectedAddress, setSelectedAddress }) => {
  const navigate = useNavigate();
  const order = useSelector((state) => state.orderSlice.order);
  const [addresses, setAddresses] = useState([]);
  const oldSelectedAddress = order.address;

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const {
          data: { addresses },
        } = await api.addresses.getAll();

        if (addresses.length === 0) {
          return navigate(`/create-order/add-address`, {
            replace: true,
          });
        }

        if (oldSelectedAddress._id) {
          addresses.sort((a) => {
            return a._id === oldSelectedAddress._id ? -1 : 1;
          });
        }

        setAddresses(addresses);
      } catch (err) {
        handleError(err);
      }
    };
    fetchAddresses();
  }, [navigate, order.kitchen._id, oldSelectedAddress._id]);

  return (
    <>
      <Typography
        variant="body1"
        style={{ textAlign: "center", marginBottom: "20px" }}
      >
        Choose from saved addresses
      </Typography>
      {addresses.map((address) => (
        <SelectableAddressCard
          key={address._id}
          address={address}
          selected={selectedAddress._id === address._id}
          onSelect={setSelectedAddress}
        />
      ))}
    </>
  );
};

export default ExistingAddresses;
