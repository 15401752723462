import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CurrentLocation from "@/components/CurrentLocation";
import LocationSearchInput from "@/components/LocationSearchInput";

const SetupLocation = () => {
  const navigate = useNavigate();

  const handleCoordinates = ({ latitude, longitude }) => {
    const location = {
      type: "gps",
      latitude,
      longitude,
    };
    localStorage.setItem("location", JSON.stringify(location));
    navigate("/");
  };

  return (
    <div style={{ margin: "30px 0px 20px" }}>
      <Typography
        variant="subtitle2"
        textAlign="center"
        style={{ marginBottom: "20px" }}
      >
        SETUP YOUR LOCATION TO GET STARTED
      </Typography>
      <div style={{ marginBottom: "20px" }}>
        <LocationSearchInput />
      </div>
      <CurrentLocation handleCoordinates={handleCoordinates} />
    </div>
  );
};

export default SetupLocation;
