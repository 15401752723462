import { Container } from "@mui/material";

import Header from "@/components/Header";

import SavedAddresses from "./components/SavedAddresses";
import { Banner, SetupLocation } from "./components";

const WelcomePage = () => {
  return (
    <>
      <Header hideBackButton />
      <Container>
        <Banner />
        <SetupLocation />
        <SavedAddresses />
      </Container>
    </>
  );
};

export default WelcomePage;
