import { useState } from "react";
import { useTheme } from "@emotion/react";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

const OtpVerificationDrawer = ({
  isOpen,
  setIsOpen,
  submitHandler,
  description = "Please enter the 6 digit code sent to your email address. Valid for 10 minutes.",
}) => {
  const [value, setValue] = useState("");
  const theme = useTheme();

  const handleChange = (e) => {
    if (e.target.value.length > 6) return;
    const result = e.target.value.replace(/\D/g, "");
    setValue(result);
  };

  const clearValue = () => {
    setValue("");
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={closeDrawer}>
      <Container style={{ height: "90vh", paddingTop: "16px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h6" style={{ textAlign: "center" }}>
            OTP verification
          </Typography>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <div>
          <Typography
            variant="body1"
            style={{ textAlign: "center", marginBottom: "16px" }}
          >
            {description}
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter 6 digit code here"
            value={value}
            onChange={handleChange}
            style={{ width: "100%", marginBottom: "16px" }}
            inputProps={{
              style: { textAlign: "center" },
            }}
            InputProps={{
              endAdornment: (
                <>
                  {value.length === 6 && (
                    <IconButton
                      onClick={() => submitHandler(value)}
                      size="small"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.main,
                      }}
                    >
                      <ArrowForwardRoundedIcon />
                    </IconButton>
                  )}
                  {value.length > 0 && value.length < 6 && (
                    <IconButton onClick={clearValue} size="small">
                      <ClearIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        </div>
      </Container>
    </Drawer>
  );
};

export default OtpVerificationDrawer;
