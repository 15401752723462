import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";

const convertToAmPm = (time) => {
  const [hourString, minute] = time.split(":");
  let hour = parseInt(hourString, 10);
  const amPm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  return `${hour}:${minute} ${amPm}`;
};

const MealTimeAlert = () => {
  const { mealTimeLabels, mealTimeDeliveryWindow } = useData();
  const { mealTime } = useSelector((state) => state.orderSlice.order);

  if (!mealTime) {
    return null;
  }

  return (
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {mealTimeLabels[mealTime]} Timings :
      </Typography>
      <Typography variant="caption" color={"text.secondary"}>
        {convertToAmPm(mealTimeDeliveryWindow[mealTime].start)} to{" "}
        {convertToAmPm(mealTimeDeliveryWindow[mealTime].end)}
      </Typography>
    </div>
  );
};

export default MealTimeAlert;
