import { useEffect, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import api from "@/api";
import { useAuth } from "@/contexts/AuthContext";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

const SavedAddresses = ({ hideList }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const {
          data: { addresses },
        } = await api.addresses.getAll();
        setAddresses(addresses);
      } catch (error) {
        handleError(error);
      }
    };

    if (isLoggedIn) {
      fetchAddresses();
    }
  }, [isLoggedIn]);

  const handleAddressClick = (address) => {
    localStorage.setItem(
      "location",
      JSON.stringify({
        type: "address",
        addressId: address._id,
      }),
    );

    navigate("/");
  };

  if (!isLoggedIn || hideList) {
    return null;
  }

  return (
    <div style={{ margin: verticalComponentMargin }}>
      <Typography variant="subtitle1">Saved address</Typography>
      <List>
        {addresses.map((address, index) => (
          <div key={index}>
            <ListItem
              onClick={() => handleAddressClick(address)}
              style={{ cursor: "pointer", paddingLeft: 0, paddingRight: 0 }}
            >
              <LocationOnIcon color="action" style={{ marginRight: "10px" }} />
              <ListItemText
                primary={address.label}
                secondary={
                  <>
                    {address.building}
                    {address.area && `, ${address.area}`}
                    {address.landmark && `, ${address.landmark}`}
                    {address.formattedAddress &&
                      `, ${address.formattedAddress}`}
                  </>
                }
                secondaryTypographyProps={{
                  style: {
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 2,
                    whiteSpace: "normal",
                  },
                  color: "text.secondary",
                }}
              />
            </ListItem>
            {index < addresses.length - 1 && <Divider />}
          </div>
        ))}
      </List>
    </div>
  );
};

export default SavedAddresses;
