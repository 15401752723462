import { useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import EndDate from "./EndDate";
import SelectPeriod from "./SelectPeriod";
import StartDate from "./StartDate";
import CalendarDrawer from "../CalendarDrawer";

const Period = () => {
  const { period } = useSelector((state) => state.orderSlice.order);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Card variant="outlined">
        <CardContent
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SelectPeriod />
          <div
            style={{
              width: "50%",
            }}
          >
            <StartDate />
            <EndDate />
            {period && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setOpen(true)}
              >
                <CalendarMonthIcon
                  style={{ color: "green", marginRight: "5px" }}
                />
                <Typography
                  variant="body2"
                  style={{
                    fontSize: "12px",
                    textDecoration: "underline",
                    textUnderlineOffset: "3px",
                    color: "green",
                  }}
                >
                  View Calendar
                </Typography>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
      <CalendarDrawer open={open} setOpen={setOpen} />
    </>
  );
};

export default Period;
