import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { AppBar, Button, Container, Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/contexts/AuthContext";

import styles from "./Header.module.css";
import Logo from "./Logo";
import UserAccountIcon from "./UserAccountIcon";

const Header = ({
  hideBackButton,
  showLocation,
  hideRightSection,
  hideLogo,
}) => {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const { location } = useSelector((state) => state.kitchensSlice);

  const navigateToLocationPage = () => {
    navigate("/location-search");
  };

  let locationText = "";
  switch (location.type) {
    case "googlePlace":
      locationText = location.formattedAddress;
      break;
    case "gps":
      locationText = location.formattedAddress;
      break;
    case "address":
      locationText =
        location.address.label + " - " + location.address.formattedAddress;
      break;
    default:
      locationText = "Setup your location";
      break;
  }

  return (
    <AppBar position="sticky">
      <Container>
        <Toolbar className={styles.header}>
          <div className={styles.left} style={{ width: showLocation && "75%" }}>
            {!hideBackButton && (
              <ArrowBackIcon
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              />
            )}
            {showLocation && (
              <div className={styles.location} onClick={navigateToLocationPage}>
                <LocationOnIcon />
                <Typography className={styles.locationText}>
                  {locationText}
                </Typography>
                <ArrowDropDownIcon />
              </div>
            )}
          </div>
          {!hideLogo && (
            <div className={styles.center}>
              <Logo />
            </div>
          )}
          <div
            className={styles.right}
            style={{ width: showLocation && "25%" }}
          >
            {!hideRightSection &&
              (authCtx.isLoggedIn ? (
                <UserAccountIcon />
              ) : (
                <Button
                  style={{ fontSize: "12px" }}
                  size="small"
                  color="inherit"
                  variant="outlined"
                  onClick={() => navigate("/auth")}
                >
                  Sign In
                </Button>
              ))}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
