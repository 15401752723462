import { Container } from "@mui/material";
import { useSelector } from "react-redux";

import BottomButton from "../BottomButton";
import Price from "../Price";

const BottomSection = () => {
  const { cuisine, mealType, mealTime, period } = useSelector(
    (state) => state.orderSlice.order,
  );

  if (!cuisine || !mealType || !mealTime || !period) {
    return null;
  }

  return (
    <Container
      style={{
        zIndex: 1,
        position: "fixed",
        left: 0,
        bottom: "20px",
      }}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          padding: "10px 15px",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Price />
        <BottomButton />
      </div>
    </Container>
  );
};

export default BottomSection;
