import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "@/components/Select";
import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import calculateEndDate from "./utils/calculateEndDate";
import generateStartDates from "./utils/generateStartDates";

const StartDate = () => {
  const { dayToNumberMap, periodToDaysMap } = useData();
  const dispatch = useDispatch();
  const { kitchen, period, startDate } = useSelector(
    (state) => state.orderSlice.order,
  );
  const deliverableDays = kitchen.configuration.deliveryDays;

  const setStartDate = useCallback(
    (date) => {
      dispatch(orderActions.updateOrder({ startDate: date }));
    },
    [dispatch],
  );

  const setEndDate = useCallback(
    (date) => {
      dispatch(orderActions.updateOrder({ endDate: date }));
    },
    [dispatch],
  );

  const options = useMemo(() => {
    const startDates = generateStartDates({
      deliverableDays,
      dayToNumberMap,
    });
    return startDates.map((date) => ({
      value: date,
      label: new Date(date).toDateString(),
    }));
  }, [deliverableDays, dayToNumberMap]);

  useEffect(() => {
    if (!startDate && options.length > 0) {
      setStartDate(options[0].value);
    }
  }, [startDate, options, setStartDate]);

  useEffect(() => {
    if (period && startDate) {
      const endDate = calculateEndDate({
        period,
        startDate,
        dayToNumberMap,
        deliverableDays,
        periodToDaysMap,
      });
      setEndDate(endDate.toDateString());
    }
  }, [
    startDate,
    period,
    deliverableDays,
    dayToNumberMap,
    periodToDaysMap,
    setEndDate,
  ]);

  return (
    <Select
      label={"Start Date"}
      options={options}
      value={startDate}
      onChange={setStartDate}
      smallSize
      style={{ marginTop: "5px", marginBottom: "15px" }}
    />
  );
};

export default StartDate;
