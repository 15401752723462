import { Card, CardContent } from "@mui/material";
import { useSelector } from "react-redux";

import ColonText from "@/components/ColonText";
import { useData } from "@/contexts/DataContext";

const OrderSummary = () => {
  const data = useData();
  const order = useSelector((state) => state.orderSlice.order);

  const {
    kitchen,
    cuisine,
    mealType,
    mealTime,
    quantity,
    period,
    startDate,
    endDate,
    price,
  } = order;

  return (
    <Card>
      <CardContent>
        <ColonText first={"Kitchen"} second={kitchen.name} />
        <ColonText first={"Cuisine"} second={data.cuisineLabels[cuisine]} />
        <ColonText first={"Meal Type"} second={data.mealTypeLabels[mealType]} />
        <ColonText first={"Meal Time"} second={data.mealTimeLabels[mealTime]} />
        <ColonText first={"Quantity"} second={quantity} />
        <ColonText first={"Period"} second={data.periodLabels[period]} />
        <ColonText
          first={"Start Date"}
          second={new Date(startDate).toDateString()}
        />
        <ColonText
          first={"End Date"}
          second={new Date(endDate).toDateString()}
        />
        <ColonText first={"Price"} second={"₹" + price + " (incl. of taxes)"} />
      </CardContent>
    </Card>
  );
};

export default OrderSummary;
