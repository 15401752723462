import { Container } from "@mui/material";

import Header from "@/components/Header";

import BottomSection from "./components/BottomSection";
import Quantity from "./components/Category/Quantity";
import { Category, KitchenDetails, Period } from "./components";
import KitchenGuard from "./hoc/KitchenGuard";

const KitchenPage = () => {
  return (
    <KitchenGuard>
      <Header />
      <Container style={{ paddingBottom: "100px" }}>
        <KitchenDetails />
        <Category />
        <Period />
        <Quantity />
        <BottomSection />
      </Container>
    </KitchenGuard>
  );
};

export default KitchenPage;
