import CloseIcon from "@mui/icons-material/Close";
import { Container, Drawer, Typography } from "@mui/material";

const BottomDrawer = ({ children, isOpen, setIsOpen, title, vh = 70 }) => {
  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={closeDrawer}>
      <div
        style={{
          padding: "16px 0px",
          backgroundColor: "#eee",
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        <Container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{title}</Typography>
          <CloseIcon onClick={closeDrawer} style={{ cursor: "pointer" }} />
        </Container>
      </div>
      <div style={{ height: vh + "vh" }}>{children}</div>
    </Drawer>
  );
};

export default BottomDrawer;
