import { Button, Card, CardContent, Radio, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import GreyCover from "@/components/GreyCover";

const SelectableAddressCard = ({ address, selected, onSelect }) => {
  const theme = useTheme();
  const {
    fullName,
    mobile,
    building,
    area,
    landmark,
    formattedAddress,
    location,
  } = address;
  const longitude = location.coordinates[0];
  const latitude = location.coordinates[1];

  console.log(latitude, longitude);

  let addressString = building;
  if (area) {
    addressString += `, ${area}`;
  }
  if (landmark) {
    addressString += `, ${landmark}`;
  }

  return (
    <Card
      style={{
        minWidth: 275,
        marginBottom: "15px",
        border: selected
          ? `2px solid ${theme.palette.primary.main}`
          : "2px solid transparent",
        cursor: "pointer",
      }}
      onClick={() => onSelect(address)}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div>
            <Radio checked={selected} />
          </div>
          <div>
            <Typography variant="h6">{fullName}</Typography>
            <Typography variant="subtitle1">{mobile}</Typography>
            <Typography variant="body2">{addressString}</Typography>
          </div>
        </div>
        <GreyCover marginYAxis={"0px"}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Typography variant="body2" fontWeight={"bold"}>
              Location
            </Typography>
            <Button
              variant="outlined"
              size="small"
              color="info"
              style={{ fontSize: "10px" }}
            >
              View on map
            </Button>
          </div>
          <Typography variant="caption">{formattedAddress}</Typography>
        </GreyCover>
      </CardContent>
    </Card>
  );
};

export default SelectableAddressCard;
