import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { orderActions } from "@/store/order";
import handleError from "@/utils/handleError";

import CompleteAddress from "./components/CompleteAddress";

const AddressPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { location: gsLocation } = useSelector((state) => state.kitchensSlice);

  const address = {
    formattedAddress: gsLocation.formattedAddress,
    location: {
      coordinates: [gsLocation.longitude, gsLocation.latitude],
    },
  };

  const submitHandler = async (addressData) => {
    try {
      const {
        data: { address },
      } = await api.addresses.create(addressData);
      dispatch(
        orderActions.updateOrder({
          address,
        }),
      );
      navigate("/create-order/summary-and-payment", {
        replace: true,
      });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Title>Address</Title>
        <CompleteAddress address={address} submitHandler={submitHandler} />
      </Container>
    </>
  );
};

export default AddressPage;
