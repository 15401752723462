import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LocationSearchInput = () => {
  const navigate = useNavigate();

  return (
    <TextField
      size="small"
      fullWidth
      variant="outlined"
      placeholder="Search a new location"
      onClick={() => navigate("/location-search")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LocationSearchInput;
