import { useState } from "react";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { verticalComponentMargin } from "@/utils/styleConstants";

import Cuisine from "./Cuisine";
import MealTime from "./MealTime";
import MealTimeAlert from "./MealTimeAlert";
import MealType from "./MealType";
import MenuDrawer from "./MenuDrawer";

const Category = () => {
  const { cuisine, mealTime, mealType } = useSelector(
    (state) => state.orderSlice.order,
  );
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            marginBottom: "15px",
          }}
        >
          <Cuisine />
          <MealType />
        </div>
        <Card variant="outlined" style={{ margin: verticalComponentMargin }}>
          <CardContent
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <MealTime />
            <div style={{ width: "50%" }}>
              <MealTimeAlert />
              {cuisine && mealTime && mealType && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "green",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  <RestaurantMenuIcon style={{ marginRight: "5px" }} />
                  <Typography
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: "3px",
                      fontSize: "12px",
                    }}
                  >
                    View Menus
                  </Typography>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
      <MenuDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Category;
