import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CurrentLocation from "@/components/CurrentLocation";
import Header from "@/components/Header";
import api from "@/api";
import useDebounce from "@/hooks/useDebounce";
import handleError from "@/utils/handleError";

import LocationSearch from "./components/LocationSearch";
import SuggestionList from "./components/SuggestionList";
import SavedAddresses from "../../../welcome/pages/WelcomePage/components/SavedAddresses";

const LocationSearchPage = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const debouncedSearchValue = useDebounce(searchValue.trim(), 300);

  useEffect(() => {
    const getPlacesAutocomplete = async () => {
      try {
        const {
          data: { suggestions },
        } = await api.maps.getPlacesAutocomplete({
          input: debouncedSearchValue,
        });
        if (suggestions) setSuggestions(suggestions);
      } catch (error) {
        handleError(error);
      }
    };

    if (debouncedSearchValue) {
      getPlacesAutocomplete();
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchValue]);

  const handleCoordinates = ({ latitude, longitude }) => {
    const location = {
      type: "gps",
      latitude,
      longitude,
    };
    localStorage.setItem("location", JSON.stringify(location));
    navigate("/");
  };

  const handleSuggestionSelect = (suggestion) => {
    const location = {
      type: "googlePlace",
      googlePlaceId: suggestion.placePrediction.placeId,
    };
    localStorage.setItem("location", JSON.stringify(location));
    navigate("/");
  };

  return (
    <>
      <Header />
      <Container>
        <LocationSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        {suggestions.length === 0 && (
          <CurrentLocation handleCoordinates={handleCoordinates} />
        )}
        {suggestions.length > 0 && (
          <SuggestionList
            suggestions={suggestions}
            onSuggestionSelect={handleSuggestionSelect}
          />
        )}
        <SavedAddresses hideList={suggestions.length > 0} />
      </Container>
    </>
  );
};

export default LocationSearchPage;
