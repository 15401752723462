import { useEffect, useRef } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const LocationSearch = ({ searchValue, setSearchValue }) => {
  const inputRef = useRef(null);

  const handleClear = () => {
    setSearchValue("");
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <TextField
      style={{ margin: "15px 0px" }}
      fullWidth
      size="small"
      placeholder="Search a new location"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear} size="small" edge="end">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LocationSearch;
