import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/orders/";

const create = async ({
  kitchenId,
  cuisine,
  mealType,
  mealTime,
  period,
  startDate,
  quantity,
  address,
  price,
}) =>
  (
    await axios.post(base, {
      kitchenId,
      cuisine,
      mealType,
      mealTime,
      period,
      startDate,
      quantity,
      address,
      price,
    })
  ).data;

const completePayment = async ({ orderId, pgPaymentId, pgSignature }) =>
  (
    await axios.post(base + orderId + "/payment", {
      pgPaymentId,
      pgSignature,
    })
  ).data;

const getAllOrders = getAll(base);

const get = async ({ orderId }) => (await axios.get(base + orderId)).data;

const updateAddress = async ({
  orderId,
  contactName,
  contactNumber,
  building,
  area,
  landmark,
  latitude,
  longitude,
  formattedAddress,
}) =>
  (
    await axios.patch(base + orderId + "/address", {
      contactName,
      contactNumber,
      building,
      area,
      landmark,
      latitude,
      longitude,
      formattedAddress,
    })
  ).data;

const cancel = async ({ orderId }) =>
  (await axios.post(base + orderId + "/cancel")).data;

const orders = {
  create,
  completePayment,
  getAll: getAllOrders,
  get,
  updateAddress,
  cancel,
};

export default orders;
