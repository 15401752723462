import { useState } from "react";
import { LocationOn } from "@mui/icons-material";
import { Button, Chip, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import BottomDrawer from "@/components/BottomDrawer";
import FormComponents from "@/components/FormComponents";

import GreyCover from "../GreyCover";

const addressBuilder = [
  {
    type: "input",
    label: "Flat / House no / Floor / Building *",
    state: "building",
    value: "",
  },
  {
    type: "input",
    label: "Nearby landmark (optional)",
    state: "landmark",
    value: "",
  },
  {
    type: "input",
    label: "Contact name *",
    state: "contactName",
    value: "",
  },
  {
    type: "mobile",
    label: "Contact number *",
    state: "contactNumber",
    value: "",
  },
];

const validationSchema = Yup.object({
  contactName: Yup.string()
    .transform((value) => value.trim())
    .required("Contact Name is required"),
  contactNumber: Yup.string()
    .transform((value) => value.trim())
    .required("Contact Number is required")
    .matches(/^\d{10}$/, "Contact Number must be 10 digits"),
  building: Yup.string()
    .transform((value) => value.trim())
    .required("Building information is required"),
  landmark: Yup.string()
    .transform((value) => value.trim())
    .notRequired(),
  label: Yup.string()
    .transform((value) => value.trim())
    .required("Address Label is required"),
});

const AddressDetailsDrawer = ({
  isOpen,
  setIsOpen,
  title,
  displayAddress,
  contactName = "",
  contactNumber = "",
  building = "",
  landmark = "",
  label = "",
  submitHandler,
}) => {
  const [otherClicked, setOtherClicked] = useState(
    Boolean(label && label !== "Home" && label !== "Work"),
  );

  const formik = useFormik({
    initialValues: {
      contactName,
      contactNumber,
      building,
      landmark,
      label,
    },
    validationSchema,
    onSubmit: submitHandler,
  });

  return (
    <BottomDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title || "Address Details"}
      vh={80}
    >
      <Container>
        <form onSubmit={formik.handleSubmit} style={{ paddingBottom: "80px" }}>
          <GreyCover>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 0px",
              }}
            >
              <LocationOn style={{ marginRight: "10px" }} />
              <Typography variant="caption">{displayAddress}</Typography>
              <Button
                size="small"
                variant="outlined"
                color="inherit"
                style={{ fontSize: 12 }}
                onClick={() => setIsOpen(false)}
              >
                Change
              </Button>
            </div>
          </GreyCover>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            style={{ margin: "10px 0px" }}
          >
            Address Details
          </Typography>
          <FormComponents formik={formik} data={addressBuilder.slice(0, 2)} />
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            style={{ marginBottom: "10px" }}
          >
            Add Address Label
          </Typography>
          <div style={{ marginBottom: "15px" }}>
            <Chip
              size="small"
              label="Home"
              clickable
              color={formik.values.label === "Home" ? "primary" : "default"}
              onClick={() => {
                setOtherClicked(false);
                formik.setFieldValue("label", "Home");
              }}
              style={{ marginRight: "10px" }}
            />
            <Chip
              size="small"
              label="Work"
              clickable
              color={formik.values.label === "Work" ? "primary" : "default"}
              onClick={() => {
                setOtherClicked(false);
                formik.setFieldValue("label", "Work");
              }}
              style={{ marginRight: "10px" }}
            />
            <Chip
              size="small"
              label="Other"
              clickable
              color={otherClicked ? "primary" : "default"}
              onClick={() => {
                setOtherClicked(true);
                formik.setFieldValue("label", "Other");
              }}
            />
          </div>
          {otherClicked && (
            <div>
              <FormComponents
                formik={formik}
                data={[
                  {
                    type: "input",
                    label: "Enter your own label",
                    state: "label",
                    value: "",
                  },
                ]}
              />
            </div>
          )}
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            style={{ marginBottom: "10px" }}
          >
            Contact Details
          </Typography>
          <FormComponents formik={formik} data={addressBuilder.slice(2)} />
          <div
            style={{
              position: "fixed",
              bottom: "0",
              left: "0",
              width: "100%",
              zIndex: 1000,
              paddingBottom: "20px",
              backgroundColor: "#fff",
            }}
          >
            <Container>
              <Button
                fullWidth
                variant="contained"
                color="success"
                type="submit"
              >
                Submit
              </Button>
            </Container>
          </div>
        </form>
      </Container>
    </BottomDrawer>
  );
};

export default AddressDetailsDrawer;
