import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

import getPropertyValue from "./getPropertyValue";

const MultiSelect = ({ formik, state, label, options, disabled = false }) => {
  const { values, touched, errors, handleBlur, setFieldValue } = formik;

  const value = getPropertyValue(values, state) || [];
  const isTouched = getPropertyValue(touched, state);
  const error = getPropertyValue(errors, state);
  const showError = Boolean(isTouched && error);

  const handleChange = (event) => {
    setFieldValue(state, event.target.value);
  };

  return (
    <div style={{ marginBottom: "3px" }}>
      <FormControl size="small" fullWidth error={showError}>
        <InputLabel id={`${state}-label`}>{label}</InputLabel>
        <Select
          size="small"
          labelId={`${state}-label`}
          multiple
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          input={<OutlinedInput label={label} />}
          disabled={disabled}
          renderValue={(selected) => (
            <div style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  size="small"
                  key={value}
                  label={options.find((option) => option.value === value).name}
                />
              ))}
            </div>
          )}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{showError ? error : " "}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default MultiSelect;
