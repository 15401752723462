import { Fragment } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Divider, List, ListItem, ListItemText } from "@mui/material";

const SuggestionList = ({ suggestions, onSuggestionSelect }) => {
  return (
    <List>
      {suggestions.map((suggestion, index) => (
        <Fragment key={suggestion?.placePrediction?.placeId}>
          <ListItem
            button
            onClick={() => onSuggestionSelect(suggestion)}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <LocationOnIcon color="action" style={{ marginRight: "10px" }} />
            <ListItemText
              primary={
                suggestion?.placePrediction?.structuredFormat?.mainText?.text
              }
              secondary={
                suggestion?.placePrediction?.structuredFormat?.secondaryText
                  ?.text
              }
            />
          </ListItem>
          {index !== suggestions.length - 1 && <Divider />}
        </Fragment>
      ))}
    </List>
  );
};

export default SuggestionList;
