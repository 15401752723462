const formatOrderPayload = (order) => {
  const address = order.address;
  return {
    kitchenId: order.kitchen._id,
    cuisine: order.cuisine,
    mealType: order.mealType,
    mealTime: order.mealTime,
    period: order.period,
    startDate: order.startDate,
    quantity: order.quantity,
    address: {
      label: address.label,
      contactName: address.contactName,
      contactNumber: address.contactNumber,
      building: address.building,
      area: address.area,
      landmark: address.landmark,
      formattedAddress: address.formattedAddress,
      latitude: address.location.coordinates[1],
      longitude: address.location.coordinates[0],
    },
    price: order.price,
  };
};

export default formatOrderPayload;
