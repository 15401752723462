import { APIProvider } from "@vis.gl/react-google-maps";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthContextProvider } from "./contexts/AuthContext";
import { DataContextProvider } from "./contexts/DataContext";
import { setupAxios } from "./setup/axios";
import { Loader } from "./setup/loading";
import { Router } from "./setup/routing";
import { ThemeProvider } from "./setup/theme";
import { store } from "./store";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  setupAxios();
  return (
    <AuthContextProvider>
      <DataContextProvider>
        <Provider store={store}>
          <ThemeProvider>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
              <BrowserRouter>
                <ToastContainer />
                <Loader />
                <Router />
              </BrowserRouter>
            </APIProvider>
          </ThemeProvider>
        </Provider>
      </DataContextProvider>
    </AuthContextProvider>
  );
};

export default App;
