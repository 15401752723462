import { Typography } from "@mui/material";

import bannerImg from "@/assets/images/kitchen.jpg";
import { verticalComponentMargin } from "@/utils/styleConstants";

const Banner = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${bannerImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "180px",
        padding: "16px",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: verticalComponentMargin,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Homely meals delivered daily
      </Typography>
      <Typography>Sign your meals now!</Typography>
    </div>
  );
};

export default Banner;
