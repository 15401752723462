import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { orderActions } from "@/store/order";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(orderActions.resetOrder());
    navigate("/kitchens");
  }, [dispatch, navigate]);

  return null;
};

export default HomePage;
