import { useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Alert, Button, Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { verticalComponentMargin } from "@/utils/styleConstants";

const LocationDisplay = ({
  formattedAddress,
  latitude,
  longitude,
  confirmLocationHandler,
  viewLocationTitle,
  btnName,
}) => {
  const { kitchen } = useSelector((state) => state.orderSlice.order);
  const [isDeliverable, setIsDeliverable] = useState(false);

  useEffect(() => {
    const getDistanceInMeters = (pointA, pointB) => {
      const { latitude: lat1, longitude: lng1 } = pointA;
      const { latitude: lat2, longitude: lng2 } = pointB;
      const toRadians = (degrees) => degrees * (Math.PI / 180);

      const R = 6371000;
      const dLat = toRadians(lat2 - lat1);
      const dLng = toRadians(lng2 - lng1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(lat2)) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      return R * c;
    };

    const checkLocation = () => {
      const userLocation = {
        latitude,
        longitude,
      };

      const kitchenLocation = {
        latitude: kitchen.address.location.coordinates[1],
        longitude: kitchen.address.location.coordinates[0],
      };

      const kitchenDeliveryRadiusInMeters =
        kitchen.configuration.deliveryRadius;

      if (
        getDistanceInMeters(kitchenLocation, userLocation) <=
        kitchenDeliveryRadiusInMeters
      ) {
        setIsDeliverable(true);
      } else {
        setIsDeliverable(false);
      }
    };

    checkLocation();
  }, [
    latitude,
    longitude,
    kitchen.address.location.coordinates,
    kitchen.configuration.deliveryRadius,
  ]);

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        {viewLocationTitle && (
          <Typography variant="subtitle1" gutterBottom fontWeight={"bold"}>
            Location
          </Typography>
        )}
        <Typography>{formattedAddress}</Typography>
        {confirmLocationHandler && isDeliverable && (
          <Button
            size="small"
            fullWidth
            variant="contained"
            endIcon={<ArrowForward />}
            style={{ marginTop: "10px" }}
            color="success"
            onClick={confirmLocationHandler}
          >
            {btnName}
          </Button>
        )}
        {!isDeliverable && (
          <Alert severity="error" style={{ marginTop: "10px" }}>
            Location not deliverable, please select another location
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default LocationDisplay;
