import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

import AddressDetailsDrawer from "@/components/Address/AddressDetailsDrawer";
import CurrentLocation from "@/components/Address/CurrentLocation";
import LocationSearch from "@/components/Address/LocationSearch";
import Map from "@/components/Address/Map";
import SuggestionList from "@/components/Address/SuggestionList";
import api from "@/api";
import useDebounce from "@/hooks/useDebounce";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

import LocationDisplay from "./LocationDisplay";

const CompleteAddress = ({ address, submitHandler }) => {
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [isCurrentLocationGeocoding, setIsCurrentLocationGeocoding] =
    useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const debouncedSearchValue = useDebounce(searchValue.trim(), 300);

  useEffect(() => {
    const { formattedAddress, location } = address;
    const [longitude, latitude] = location.coordinates;
    setFormattedAddress(formattedAddress);
    setLatitude(latitude);
    setLongitude(longitude);
  }, [address]);

  useEffect(() => {
    const getPlacesAutocomplete = async () => {
      try {
        const {
          data: { suggestions },
        } = await api.maps.getPlacesAutocomplete({
          input: debouncedSearchValue,
        });
        if (suggestions) setSuggestions(suggestions);
      } catch (error) {
        handleError(error);
      }
    };

    if (debouncedSearchValue) {
      getPlacesAutocomplete();
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchValue]);

  const handleSuggestionSelect = async (suggestion) => {
    try {
      const {
        placePrediction: { placeId },
      } = suggestion;

      const {
        data: {
          formattedAddress,
          location: { latitude, longitude },
        },
      } = await api.maps.getPlaceDetails({ placeId });

      setLatitude(latitude);
      setLongitude(longitude);
      setFormattedAddress(formattedAddress);
      setSuggestions([]);
    } catch (error) {
      handleError(error);
    }
  };

  const reverseGeocodeLocation = async ({ latitude, longitude }) => {
    try {
      const {
        data: { results },
      } = await api.maps.reverseGeocode({ latitude, longitude });
      const { lat, lng } = results[0].geometry.location;
      const { formatted_address } = results[0];
      setLatitude(lat);
      setLongitude(lng);
      setFormattedAddress(formatted_address);
      setSuggestions([]);
      setSearchValue("");
    } catch (error) {
      handleError(error);
    }
  };

  const handleCurrentLocationGeocoding = async ({ latitude, longitude }) => {
    setIsCurrentLocationGeocoding(true);
    await reverseGeocodeLocation({ latitude, longitude });
    setIsCurrentLocationGeocoding(false);
  };

  const handleAddressEdit = ({
    contactName,
    contactNumber,
    building,
    area,
    landmark,
    label,
  }) => {
    submitHandler({
      contactName,
      contactNumber,
      area,
      building,
      landmark,
      latitude,
      longitude,
      formattedAddress,
      label,
    });
    setIsOpen(false);
  };

  return (
    <>
      <LocationSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <CurrentLocation onLocationCapture={handleCurrentLocationGeocoding} />
      {suggestions.length > 0 && (
        <SuggestionList
          suggestions={suggestions}
          onSuggestionSelect={handleSuggestionSelect}
        />
      )}
      {suggestions.length === 0 && formattedAddress && (
        <LocationDisplay
          formattedAddress={formattedAddress}
          latitude={latitude}
          longitude={longitude}
          confirmLocationHandler={() => setIsOpen(true)}
          btnName={"Complete Address"}
        />
      )}
      {suggestions.length === 0 &&
        latitude &&
        longitude &&
        !isCurrentLocationGeocoding && (
          <>
            <Alert severity="info" style={{ margin: verticalComponentMargin }}>
              Drag the marker below to your exact location
            </Alert>
            <Map
              latitude={latitude}
              longitude={longitude}
              draggable
              handleDragEnd={reverseGeocodeLocation}
            />
          </>
        )}
      <AddressDetailsDrawer
        title="Complete Address"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        contactName={address.contactName}
        contactNumber={address.contactNumber}
        building={address.building}
        area={address.area}
        landmark={address.landmark}
        submitHandler={handleAddressEdit}
        label={address.label}
      />
    </>
  );
};

export default CompleteAddress;
