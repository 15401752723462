import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

const EndDate = () => {
  const { endDate } = useSelector((state) => state.orderSlice.order);

  if (!endDate) {
    return null;
  }

  return (
    <TextField
      size="small"
      fullWidth
      label="End Date"
      value={endDate}
      InputProps={{
        readOnly: true,
        style: { fontSize: "12px", marginBottom: "10px" },
      }}
    />
  );
};

export default EndDate;
