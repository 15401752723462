import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PageGuard = ({ children }) => {
  const {
    kitchen,
    cuisine,
    mealType,
    mealTime,
    period,
    quantity,
    price,
    address,
    startDate,
  } = useSelector((state) => state.orderSlice.order);

  if (
    !kitchen._id ||
    !cuisine ||
    !mealType ||
    !mealTime ||
    !period ||
    !quantity ||
    !price ||
    !address._id ||
    !startDate
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PageGuard;
